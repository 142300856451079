window.$ = window.jQuery = require('jquery');
require('./bootstrap');


$(function() {
	$('#save').click(function() {
		var min = $('#min').val();
		var max = $('#max').val();
		if(min.length + max.length == 0) {
			alert('Min value and Max value cannot be null at the same time.');
		} else {
			var url = $('#setting_form').attr('action');
			$.post(url, $("#setting_form").serialize()).done(function() {
			    // alert("success");
			}).fail(function(data) {
			    alert(data.responseJSON.message);
			}).always(function() {
				location.reload();
			});
		}
	});


	$('.edit').click(function() {
		var code = $(this).data('code');
		$('#code').val(code).change();
		$('#min').val($('#'+code).children().eq(1).text());
		$('#max').val($('#'+code).children().eq(2).text());
		$('#notify_id').val($('#'+code).children().eq(3).data('id'));
		$('#notify_rate').val($('#'+code).children().eq(4).data('rate'));
		var enabled = $('#'+code).children().eq(5).text();
		if(enabled == 'Yes') {
			$('#enabled').prop('checked', true);
		} else {
			$('#enabled').prop('checked', false);
		}
	});

	$('.del').click(function() {
		var url = $('#setting_form').attr('action');
		var data = $("#setting_form").serializeArray();
		var id = $(this).data('id');
		data.push({'name': 'id', 'value':id});
		data.push({'name': '_method', 'value':'DELETE'});
		$.post(url, data).done(function() {
		    // alert("success");
		}).fail(function(data) {
		    alert(data.responseJSON.message);
		}).always(function() {
			location.reload();
		});

	});


	$('#notify-type').change(function() {
		var newType = $(this).children("option:selected").val();
		var newHref = window.location.href.replace(/type=\w+/, 'type='+newType);
		window.location.href = newHref;
    });

    $('.notify-del').click(function() {
		var url = $(this).data('url');
		var csrf = $('meta[name=csrf-token]').attr('content');
		var data = $("x").serializeArray();
		data.push({'name': '_token', 'value': csrf});
		data.push({'name': 'id', 'value': $(this).data('id')});
		data.push({'name': '_method', 'value':'DELETE'});
		console.log(data);
		$.post(url, data).done(function() {
		    alert("success");
		}).fail(function(data) {
		    alert(data.responseJSON.message);
		}).always(function() {
			location.reload();
		});

	});

});